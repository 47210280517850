/*
 * @version: 1.0
 * @Date: 2023-04-13 15:37:25
 * @LastEditors: cyx 
 */
import request from '@/utils/request'

// 预警中心主页面展示
export function getMessageList(params) {
  return request({
    url: `/pc/message/show`,
    method: 'GET',
    params
  })
}

// 预警中心详情查看
export function getMessageDetails(params) {
  return request({
    url: `/pc/message/details`,
    method: 'GET',
    params
  })
}

// 消息中心
export function getUnreadInfo(params) {
  return request({
    url: '/pc/message/show',
    method: 'GET',
    params
  })
}

// 标记为已读
export function changeInfo(params) {
  return request({
    url: `/pc/message/batchAmend`,
    method: 'PUT',
    data: params
  })
}

export function getHeaderInfo(params) {
  return request({
    url: 'pc/message/homeShow',
    method: 'GET',
    params
  })
}

export function getDetails(data) {
  return request({
    url: `/pc/details/fingerprint/details/${data.documentId}/v2.6.7`,
    method: 'GET'
  })
}

// 印章柜预警中心详情查看
export function getCabinetDetails(params) {
  return request({
    url: `/pc/message/cabinet/details`,
    method: 'GET',
    params
  })
}
