/*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-07-04 00:01:14
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-12-04 14:56:25
 * @FilePath: \yda_web_manage\src\utils\loadMore.js
 * @Description:
 *
 */
import { Directive, App } from 'vue'

const debounce = function(func, delay) {
  let timer = null
  return function() {
    if (timer) clearTimeout(timer)
    timer = null
    let self = this
    let args = arguments
    timer = setTimeout(() => {
      func.apply(self, args)
    }, delay)
  }
}

const loadMore = {
  mounted(el, binding, vnode) {
    // console.log('我开始了')
    // console.log('el的值', el)
    // console.log('binding的值', binding)
    // console.log('vnode的值', vnode)
    el.setAttribute('load-more-disabled', 'false')
    const { expand } = binding.modifiers
    // 使用更丰富的功能，支持父组件的指令作用在指定的子组件上
    if (expand) {
      /**
       * target 目标DOM节点的类名
       * distance 减少触发加载的距离阈值，单位为px
       * func 触发的方法\-0
       * delay 防抖时延，单位为ms
       * load-more-disabled 是否禁用无限加载
       */
      let { target, distance = 0, func, delay = 200 } = binding.value
      if (typeof target !== 'string') return
      let targetEl = el.querySelector(target)
      // console.log('targetEl', targetEl)
      if (!targetEl) {
        console.log('找不到容器')
        return
      }
      binding.handler = function() {
        const { scrollTop, scrollHeight, clientHeight } = targetEl

        let disabled = el.getAttribute('load-more-disabled')
        // console.log('disabled', disabled)
        // disabled = vnode[disabled] || disabled
        if (scrollHeight <= scrollTop + clientHeight + distance) {
          if (disabled == 'true') return
          // console.log('走这里了嘛11')
          func && func()
        }
      }
      targetEl.addEventListener('scroll', binding.handler)
    } else {
      binding.handler = debounce(function() {
        const { scrollTop, scrollHeight, clientHeight } = el
        if (scrollHeight === scrollTop + clientHeight) {
          binding.value && binding.value()
        }
      }, 200)
      el.addEventListener('scroll', binding.handler)
    }
  },
  unmounted(el, binding) {
    console.log('卸载了 ')
    let { arg } = binding
    // 使用更丰富的功能，支持父组件的指令作用在指定的子组件上
    if (arg === 'expand') {
      /**
       * target 目标DOM节点的类名
       * offset 触发加载的距离阈值，单位为px
       * method 触发的方法
       * delay 防抖时延，单位为ms
       */
      const { target } = binding.value
      if (typeof target !== 'string') return
      let targetEl = el.querySelector(target)
      targetEl && targetEl.removeEventListener('scroll', binding.handler)
      targetEl = null
    } else {
      el.removeEventListener('scroll', binding.handler)
      el = null
    }
  }
}

export function setupLoadMoreDirective(app) {
  app.directive('loadMore', loadMore)
}

export default loadMore
