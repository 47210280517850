/*
 * @version: 1.0
 * @Date: 2023-04-17 13:49:15
 * @LastEditors: 杨慢慢
 */
import { createRouter, createWebHistory } from 'vue-router'
// 配置路由
const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/User/Login')
  },
  {
    path: '/binding', // 企业微信登录手机号没有绑定手机号
    name: 'binding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/User/binding')
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/personalCenter')
  },
  {
    path: '/signedInstrument', // 签署完成提示页面
    name: 'signedInstrument',
    component: () => import(/* webpackChunkName: "login" */ '@/views/User/signedInstrument')
  },
  {
    path: '/404',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404" */ '@/views/Error/404')
  },
  {
    path: '/error',
    name: 'error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404" */ '@/views/err/error')
  },
  // {
  //   path: '/formDesign',
  //   name: 'formDesign',
  //   component: () => import('@/views/formDesign/index.vue'),
  //   meta: {
  //     title: '拖拽'
  //   }
  // },
  {
    path: '/businessAdd',
    name: 'businessAdd',
    component: () => import(/* webpackChunkName: "businessAdd" */ '@/views/businessManage/flowManage/business/add.vue'),
    meta: {
      title: '流程名称'
    }
  },
  {
    path: '/recordsBusinessAdd',
    name: 'recordsBusinessAdd',
    component: () => import(/* webpackChunkName: "businessAdd" */ '@/views/businessManage/flowManage/archive/add.vue'),
    meta: {
      title: '归档流程'
    }
  },
  {
    path: '/operationLogDetail',
    name: 'globalOperationLogDetail',
    component: () =>
      import(/* webpackChunkName: "logDetail" */ '@/views/businessManage/operationLog/logDetail/index.vue'),
    meta: {
      title: '操作日志详情'
    }
  }
  // {
  //   path: '/processDesign',
  //   name: 'processDesign',
  //   component: () => import('@/views/processDesign/index.vue'),
  //   meta: {
  //     title: '流程'
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

export default router
