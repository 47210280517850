/*
 * @version: 1.0
 * @Date: 2023-06-13 09:43:26
 * @LastEditors: chenxu chenxu@libawall.cn
 */
// 严凯 V2.2.0
export const pageTitle = {
  '/seal/apply': '用印信息',
  '/seal/apply/add': '添加申请信息',
  '/seal/apply/addElec': '添加申请',
  '/seal/apply/detail': '用印信息',
  '/seal/apply/elecDetail': '用印信息',
  '/seal/applyList': '用印信息',
  '/seal/applyList/detail': '用印信息',
  '/seal/applyWaitList': '用印信息',
  '/seal/applyCompleteList': '用印信息',
  '/seal/applyArchiveList': '已归档',
    // '/approval/notApproval': '审批信息',
  '/approval/approved': '审批信息',
  '/approval/notApproval': '待审批',
  '/approval/notApproval/pendingdetail': '待审批详情',
  '/approval/alreadyApproval': '已审批',
  '/approval/alreadyApproval/approveddetail': '已审批详情',
  '/approval/informPeople': '抄送信息',
  // "/approval/informPeople/entityinformdetail": "抄送详情",
  // "/approval/informPeople/elecinformdetail": "抄送详情",
  '/approval/informPeople/detail': '抄送详情',
  '/sealManage': '印章信息',
  '/sealManage/add': '添加印章信息',
  '/sealManage/adds': '修改印章信息',
  //TODO：批量设置
  '/sealManage/setSealManage': '设置印章保管员',
  '/sealManage/setPrivilegedPerson': '设置特权用印人',
  '/sealManage/setViewer': '设置数据查看人',
  '/equipmentManage': '终端信息',
  '/equipmentManage/detail': '终端详情',
  '/equipmentManage/yzgDetail': '设备信息',
  '/businessManage/department': '部门信息',
  '/businessManage/department/add': '添加部门信息',
  '/businessManage/staff': '组织架构信息',
  '/businessManage/staff/add': '添加人员信息',
  '/businessManage/staff/edit': '修改人员信息',
  '/businessManage/role': '角色管理',
  '/businessManage/role/add': '添加角色信息',
  '/businessManage/business': '流程名称管理',
  '/businessManage/role/edit': '修改角色信息',
  '/businessManage/process': '审批流程信息',
  '/businessManage/process/add': '添加审批流程信息',
  '/businessManage/process/edit': '修改审批流程信息',
  '/businessManage/fingerprint': '指纹管理',
  '/businessManage/configure': '参数设置',
  '/businessManage/fileType': '文件类型管理',
  '/dataCenter': '数据信息',
  '/selfHelp': '自助申请',
  '/warningCenter': '预警信息',
  '/elecSeal/compInfo': '企业信息',
  '/elecSeal/fileManage': '文件信息',
  // TODO:消息管理中心
  '/businessManage/message': '消息管理中心',
  '/businessManage/operationLog': '操作日志',
  '/businessManage/operationLog/detail': '操作内容',
  '/archiveManage': '归档信息',
  '/businessManage/flowManage/archive': '归档流程管理',
  '/businessManage/flowManage/business':'用印流程管理',
  '/archiveManage/detail': '归档详情',
  '/seal/applyWaitList/detail': '用印信息',
  '/seal/applyCompleteList/detail': '用印信息',
  '/seal/applyArchiveList/detail': '归档详情',
}
