<template>
  <!-- 文本滚动 -->
  <div class="text-scroll" ref="textScroll">
    <div class="content" @webkitAnimationEnd="animationEnd" ref="content" :style="scrollAnimation">
      <!-- 默认插槽，插入滚动内容 -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextScroll',
  props: {
    /* 滚动方向
     * value: up、down、left、right
     */
    direction: {
      default: 'left',
      type: String
    },
    //滚动速度 单位px/s
    speed: {
      default: 55,
      type: Number
    }
  },
  data() {
    return {
      //滚动动画
      scrollAnimation: {
        '--text-scroll-height': '',
        '--text-scroll-width': '',
        animation: ''
      }
    }
  },
  methods: {
    //设置滚动动画
    setScrollAnimation() {
      //获取文本滚动实际显示宽度高度，设为css变量，用于设置动画开始起始位置
      let height = this.$refs.textScroll.offsetHeight
      let width = this.$refs.textScroll.offsetWidth
      this.scrollAnimation['--text-scroll-height'] = `${height}px`
      this.scrollAnimation['--text-scroll-width'] = `${width}px`
      //滚动长度、时间
      const content = document.querySelector('.content')
      let scrollLength, time
      //根据滚动方向来设置不同的滚动动画
      switch (this.direction) {
        case 'up':
          scrollLength = this.$refs.content.offsetHeight + height
          time = scrollLength / this.speed
          this.scrollAnimation.animation = `up-scroll linear ${time}s infinite`
          break
        case 'down':
          scrollLength = this.$refs.content.offsetHeight + height
          time = scrollLength / this.speed
          this.scrollAnimation.animation = `up-scroll linear ${time}s infinite reverse`
          break
        case 'left':
          scrollLength = this.$refs.content.offsetWidth + width
          time = scrollLength / this.speed
          this.scrollAnimation.animation = `center-scroll linear ${time}s`
          break
        case 'right':
          scrollLength = this.$refs.content.offsetWidth + width
          time = scrollLength / this.speed
          this.scrollAnimation.animation = `left-scroll linear ${time}s infinite reverse`
          break
      }
    },
    animationEnd() {
      if (this.direction !== 'left') {
        return
      }
      let width = this.$refs.textScroll.offsetWidth
      let scrollLength = this.$refs.content.offsetWidth + width
      let time = scrollLength / this.speed
      this.scrollAnimation.animation = `left-scroll linear ${time}s infinite`
    }
  },
  mounted() {
    this.setScrollAnimation()
  },
  updated() {
    //当插槽内容更新重新设置滚动动画
    // this.setScrollAnimation()
  }
}
</script>

<style scoped lang="scss">
.text-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  .content {
    height: fit-content;
    width: fit-content;
  }
}
</style>

<style lang="less">
.text-scroll {
  .content {
    @keyframes up-scroll {
      0% {
        transform: translateY(var(--text-scroll-height));
      }

      100% {
        transform: translateY(-100%);
      }
    }

    @keyframes center-scroll {
      0% {
        transform: translateX(0%);
      }

      100% {
        transform: translateX(-100%);
      }
    }
    @keyframes left-scroll {
      0% {
        transform: translateX(var(--text-scroll-width));
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }
}
</style>
