/*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-05-05 16:31:47
 * @LastEditors: cyx
 * @LastEditTime: 2023-11-30 17:19:47
 * @FilePath: \yda_web_manage\src\router\async-router.js
 * @Description:路由配置
 *
 */
// 严凯 V2.2.0
import BasicLayout from '@/layout/BasicLayout'
import RouteView from '@/layout/RouteView'
import store from '@/store'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import {
  getRoutePermissionList,
  getUserInfo,
  getDetailsApplyType
} from '@/apis/user'
import router from './config-router'
import { notification } from 'ant-design-vue'

const setRouteComponent = (item, level) => {
  let routes = {
    path: item.url,
    name: item.url.split('/')[level],
    meta: { title: item.permissionsName },
    component: () => import(`@/views${item.url}`)
  }
  if (item.type == 1) {
    if (item.child && level == 1) {
      if (item.child[0].type === 2) {
        //权限类型(1-菜单权限；2-接口权限)
        routes.meta.permission = item.child.map((item) => item.permissionsCode)
        if (routes.name === 'sealManage') {
          routes.meta.keepAlive = true
          return [
            routes,
            {
              path: item.url + '/add',
              name: routes.name + 'add',
              meta: { title: `${routes.meta.title},添加印章` },
              component: () => import(`@/views/sealManage/add`)
            },
            {
              path: item.url + '/adds',
              name: routes.name + 'adds',
              meta: { title: `${routes.meta.title},修改印章` },
              component: () => import(`@/views/sealManage/add`)
            },
            // TODO:印章管理-批量设置
            {
              path: item.url + '/setSealManage',
              name: routes.name + 'setSealManage',
              meta: { title: `${routes.meta.title},设置印章保管员` },
              component: () => import(`@/views/sealManage/batchSetting`)
            },
            {
              path: item.url + '/setPrivilegedPerson',
              name: routes.name + 'setPrivilegedPerson',
              meta: { title: `${routes.meta.title},设置特权用印人` },
              component: () => import(`@/views/sealManage/batchSetting`)
            },
            {
              path: item.url + '/setViewer',
              name: routes.name + 'setViewer',
              meta: { title: `${routes.meta.title},设置数据查看人` },
              component: () => import(`@/views/sealManage/batchSetting`)
            }
          ]
        }
        if (routes.name === 'equipmentManage') {
          routes.meta.keepAlive = true
          return [
            routes,
            {
              path: item.url + '/detail',
              name: routes.name + 'detail',
              meta: { title: `${routes.meta.title},终端详情` },
              component: () => import(`@/views/equipmentManage/detail`)
            },

            {
              path: item.url + '/yzgDetail',
              name: routes.name + 'yzgDetail',
              meta: { title: `${routes.meta.title},设备信息` },
              component: () => import(`@/views/equipmentManage/yzgDetail`)
            }
          ]
        }
      } else {
        routes.redirect = item.child[0].url
        routes.component = RouteView
        routes.children = []
      }
    } else {
      if (routes.name === 'archiveManage') {
        return [
          routes,
          {
            path: item.url + '/detail',
            name: routes.name + 'detail',
            meta: { title: `${routes.meta.title},归档详情` },
            component: () => import(`@/views/archiveManage/detail`)
          }
        ]
      }
    }
    if (level == 2) {
      routes.meta.permission = item.child
        ? item.child.map((item) => item.permissionsCode)
        : null
      if (routes.name === 'applyWaitList') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/detail',
            name: routes.name + 'detail',
            meta: { title: `${routes.meta.title},用印详情` },
            component: () => import(`@/views/seal/applyWaitList/detail_3`)
          }
        ]
      }
      if (routes.name === 'applyCompleteList') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/detail',
            name: routes.name + 'detail',
            meta: { title: `${routes.meta.title},用印详情` },
            component: () => import(`@/views/seal/applyCompleteList/detail_3`)
          }
        ]
      }
      if (routes.name === 'applyArchiveList') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/detail',
            name: routes.name + 'detail',
            meta: { title: `${routes.meta.title},用印详情` },
            component: () => import(`@/views/seal/applyArchiveList/detail_3`)
          }
        ]
      }
      if (routes.name === 'apply') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/detail',
            name: routes.name + 'detail',
            meta: { title: `${routes.meta.title},用印详情` },
            component: () => import(`@/views/seal/apply/detail_3`)
          },
          {
            path: item.url + '/add',
            name: routes.name + 'add',
            meta: { title: `${routes.meta.title},添加申请` },
            component: () => import(`@/views/seal/apply/add`)
          },
          {
            path: item.url + '/addElec',
            name: routes.name + 'addElec',
            meta: { title: `${routes.meta.title},添加申请` },
            component: () => import(`@/views/seal/apply/addElec`)
          },
          {
            path: item.url + '/elecdetail',
            name: routes.name + 'elecdetail',
            meta: { title: `${routes.meta.title},用印详情` },
            component: () => import(`@/views/seal/apply/elecDetail`)
          }
        ]
      }
      // 审批中心：抄送我的
      if (routes.name === 'informPeople') {
        routes.meta.keepAlive = true
        return [
          routes,
          // {
          //   path: item.url + '/entityinformdetail',
          //   name: routes.name + 'entityinformdetail',
          //   meta: { title: `${routes.meta.title},抄送详情` },
          //   component: () => import(`@/views/approval/informPeople/entityInformDetail`)
          // },
          // {
          //   path: item.url + '/elecinformdetail',
          //   name: routes.name + 'elecinformdetail',
          //   meta: { title: `${routes.meta.title},抄送详情` },
          //   component: () => import(`@/views/approval/informPeople/elecInformDetail.vue`)
          // },
          {
            path: item.url + '/detail',
            name: routes.name + 'detail',
            meta: { title: `${routes.meta.title},抄送详情` },
            component: () =>
              import(`@/views/approval/informPeople/detail_3.vue`)
          }
        ]
      }
      //审批中心-用印审批详情
      //审批中心-已审批详情
      if (routes.name === 'alreadyApproval') {
        routes.meta.keepAlive = true
        return [
          routes,
          // 已审批详情
          {
            path: item.url + '/approveddetail',
            name: routes.name + 'approveddetail',
            meta: { title: `${routes.meta.title},已审批详情` },
            component: () => import(`@/views/approval/approvalDetail_3/index`)
          }
        ]
      }
      //审批中心-待审批详情
      if (routes.name === 'notApproval') {
        routes.meta.keepAlive = true
        return [
          routes,
          // 待审批详情
          {
            path: item.url + '/pendingdetail',
            name: routes.name + 'pendingdetail',
            meta: { title: `${routes.meta.title},待审批详情` },
            component: () => import(`@/views/approval/approvalDetail_3/index`)
          }
        ]
      }
      if (routes.name === 'role') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/add',
            name: routes.name + 'add',
            meta: { title: `${routes.meta.title},添加角色` },
            component: () => import(`@/views/businessManage/role/add`)
          },
          {
            path: item.url + '/edit',
            name: routes.name + 'edit',
            meta: { title: `${routes.meta.title},修改角色` },
            component: () => import(`@/views/businessManage/role/edit`)
          }
        ]
      }
      if (routes.name === 'staff') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/add',
            name: routes.name + 'add',
            meta: { title: `${routes.meta.title},添加人员` },
            component: () => import(`@/views/businessManage/staff/add`)
          },
          {
            path: item.url + '/edit',
            name: routes.name + 'edit',
            meta: { title: `${routes.meta.title},修改人员` },
            component: () => import(`@/views/businessManage/staff/edit`)
          }
        ]
      }
      if (routes.name === 'department') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/add',
            name: routes.name + 'add',
            meta: { title: `${routes.meta.title},添加部门` },
            component: () => import(`@/views/businessManage/department/add`)
          }
        ]
      }
      if (routes.name === 'process') {
        routes.meta.keepAlive = true
        return [
          routes
          // {
          //   path: item.url + '/add',
          //   name: routes.name + 'add',
          //   meta: { title: `${routes.meta.title},添加审批流程` },
          //   component: () => import(`@/views/businessManage/process/add`)
          // },
          // {
          //   path: item.url + '/edit',
          //   name: routes.name + 'edit',
          //   meta: { title: `${routes.meta.title},修改审批流程` },
          //   component: () => import(`@/views/businessManage/process/edit`)
          // }
        ]
      }
      if (routes.name === 'operationLog') {
        routes.meta.keepAlive = true
        return [
          routes,
          {
            path: item.url + '/detail',
            name: routes.name + 'Detail',
            meta: { title: `${routes.meta.title},操作内容` },
            component: () =>
              import(
                /* webpackChunkName: "logDetail" */ `@/views/businessManage/operationLog/logDetail`
              )
          }
        ]
      }
      if (routes.name === 'archiveManage') {
        return [
          routes,
          {
            path: item.url + '/detail',
            name: routes.name + 'detail',
            meta: { title: `${routes.meta.title},归档详情` },
            component: () => import(`@/views/archiveManage/detail`)
          }
        ]
      }
      if (routes.name == 'flowManage') {
        routes.redirect = item.child[0].url
        routes.component = RouteView
        routes.children = []
        return [routes]
      }
      if (routes.name == 'fileManage') {
      }
    }
    if (level == 3) {
      return [routes]
    }
  }
  return [routes]
}
let navNum = 0
const deepLoopRoutes = (iterationArr = [], targetArr = [], level) => {
  iterationArr.forEach((item) => {
    const routes = setRouteComponent(item, level)
    if (item.child && item.child[0].type !== 2) {
      deepLoopRoutes(item.child, routes[0].children, level + 1)
    }
    targetArr.push(...routes)
  })
}
const deepLoopNav = (iterationArr, targetArr) => {
  iterationArr.forEach((item) => {
    navNum++
    const navItem = {}
    if (item.meta.title && item.children) {
      navItem.children = []
      deepLoopNav(item.children, navItem.children)
    }
    navItem.key = item.name
    navItem.title = item.meta.title
      ? item.meta.title
      : item.children[0].meta.title
    navItem.path = item.path
    navItem.icon = navNum === 1 ? item.name : null
    // !navItem.path.includes('detail') && targetArr.push(navItem)
    if (
      navItem.path.indexOf('yzgDetail') == -1 &&
      navItem.path.indexOf('detail') == -1 &&
      navItem.path.indexOf('add') == -1 &&
      navItem.path.indexOf('fence') == -1 &&
      navItem.path.indexOf('edit') == -1 &&
      navItem.path.indexOf('set') == -1
    ) {
      targetArr.push(navItem)
      // console.log('targetArr的值', targetArr)
    }
    navNum--
  })
}
const asyncRouter = async () => {
  const { data } = await getRoutePermissionList()
  // console.log('-----665656------', data)
  if (data && !data.length) {
    notification['warning']({
      message: '提示',
      description: '请重新登录'
    })
    localStorage.removeItem('yda-admin-userInfo')
    localStorage.removeItem('checkedRows')
    localStorage.removeItem('paginationParams')
    router.push(`/login`)
  }

  const { data: user } = await getUserInfo()
  const type = await getDetailsApplyType()
  if (type.data == 1) {
    data.push({
      child: null,
      icon: '',
      id: 9168,
      isSystem: 1,
      leaf: true,
      parentId: 1,
      permissionsCode: 'PC_DATA_CENTER',
      permissionsId: 8,
      permissionsName: '自助申请',
      roleCode: '00001',
      roleId: 2,
      roleName: '超级管理员',
      roleRelId: 22,
      roleType: 2,
      sortIndex: 7,
      status: 1,
      type: 1,
      url: '/selfHelp'
    })
  }
  const userInfo = user
  userInfo.userName = user.userName
  userInfo.allEnterprise = user.result.allEnterprise
  localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
  store.commit(`user/${SET_USER_INFO}`, userInfo)
  const routes = {
    key: '',
    name: 'index',
    path: '/',
    component: BasicLayout,
    redirect: data[0].url || '/dashboard/analysis',
    // redirect: '/dashboard/analysis',
    meta: {
      title: '工作台'
    },
    children: []
  }
  const navList = []
  deepLoopRoutes(data, routes.children, 1)
  // console.log('navList', navList)
  deepLoopNav(routes.children, navList)
  // console.log('面包屑', routes)
  // console.log('瞅瞅routes里面有啥子',routes);
  return { routes, navList }
}
export default asyncRouter
