// 严凯 V2.2.0

import request from '@/utils/request'
const api = {
  role: '/pc/role',
  department: '/pc/organization/department',
  user: '/pc/user',
  process: '/pc/flow',
  staff: '/pc/organization/staff'
}

function objToString(obj) {
  const strArr = []
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      strArr.push(`${key}=${obj[key]}`)
    }
  }
  const str = strArr.length ? '?' + strArr.join('&') : ''
  return str
}

// 获取角色数据
export function getRoleList(params) {
  return request({
    url: `${api.role}`,
    method: 'GET',
    params
  })
}

// 新增角色
export function addNewRole(data) {
  return request({
    url: `${api.role}`,
    method: 'POST',
    data
  })
}

// 修改角色
export function editRole(data) {
  return request({
    url: `${api.role}`,
    method: 'PUT',
    data
  })
}

// 删除角色
export function deleteRole(params) {
  return request({
    url: `${api.role}/${params.id}`,
    method: 'DELETE'
  })
}
// 获取个人权限
export function getSelfPermission(params) {
  return request({
    url: `${api.role}/info/${params.id}`,
    method: 'GET'
  })
}

// 获取所有权限
export function getPermissionList(params) {
  return request({
    url: `${api.role}/permission/all`,
    method: 'GET',
    params
  })
}

// 获取部门列表
export function getDepartmentOrganizational(params) {
  return request({
    url: `${api.department}/getOrganizational`,
    method: 'GET',
    params
  })
}

export function getDepartmentShow(params) {
  return request({
    url: '/pc/details/department/show',
    method: 'GET',
    params
  })
}

// 获取部门展示列表
export function getDepartmentList() {
  return request({
    url: `${api.department}`,
    method: 'GET'
  })
}

// 添加部门列表
export function addDepartment(params) {
  return request({
    url: `${api.department}`,
    method: 'POST',
    data: params
  })
}

// 修改部门列表
export function editDepartment(params) {
  return request({
    url: `${api.department}`,
    method: 'PUT',
    data: params
  })
}
// 批量导入部门
export function importDepartment(params) {
  return request({
    url: '/pc/organization/importDepartment',
    method: 'POST',
    params
  })
}

// 删除部门列表
export function deleteDepartment(data) {
  return request({
    url: `${api.department}/${data.id}`,
    method: 'DELETE',
    data
  })
}

// 获取人员列表展示
export function getUserList(params) {
  return request({
    url: `${api.staff}`,
    method: 'GET',
    params
  })
}

// 修改人员
export function addUser(params) {
  return request({
    url: `${api.staff}`,
    method: 'POST',
    data: params
  })
}

// 修改人员密码
export function reUserPass(params) {
  return request({
    url: `${api.user}/rePassword`,
    method: 'POST',
    params
  })
}

// 修改人员
export function editUser(params) {
  return request({
    url: `${api.staff}`,
    method: 'PUT',
    data: params
  })
}

// 获取组织架构-组织-树状结构
export function getDepartmentTab() {
  return request({
    url: `/pc/organization/department/tab`,
    method: 'GET'
  })
}

// 排序组织架构-组织-树状结构
export function sortDepartmentTab(enterpriseId, params) {
  return request({
    url: `/pc/organization/department/sort/${enterpriseId}`,
    method: 'POST',
    data: params
  })
}
// 获取人员组织架构
export function getOrganizational() {
  return request({
    url: `${api.user}/getOrganizational`,
    method: 'GET'
  })
}

// 获取人员角色类型
export function getRoleClassify() {
  return request({
    url: `${api.user}/listRole`,
    method: 'GET'
  })
}

// 批量导入人员
export function importUser(params) {
  return request({
    url: '/pc/organization/importStaff',
    method: 'POST',
    params: { ...params, timeout: 600000 }
  })
}

// 删除人员
export function deleteUser(params) {
  return request({
    url: `${api.staff}/${params.id}`,
    method: 'DELETE'
  })
}
//批量删除人员
export function deleteSelectUser(params) {
  return request({
    url: `/pc/organization/staff/batch`,
    method: 'DELETE',
    data: params
  })
}

// 校验人员
export function verifyUser(params) {
  return request({
    url: `${api.user}/verify/${params.id}`,
    method: 'GET'
  })
}

// 获取人员
export function getLbwList() {
  return request({
    url: `${api.user}/getUserByLibawall`,
    method: 'GET'
  })
}

// 导出人员
export function importLbwUser(params) {
  return request({
    url: `${api.user}/importUserByLibawall`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 获取审批流程列表展示
export function getProcessList(params) {
  return request({
    url: `${api.process}`,
    method: 'GET',
    params
  })
}

// 添加流程
export function addProcess(params) {
  return request({
    url: `${api.process}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 修改流程
export function editProcess(params) {
  return request({
    url: `${api.process}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 删除流程
export function deleteProcess(params) {
  return request({
    url: `${api.process}/${params.id}`,
    method: 'DELETE'
  })
}

export function getapproval(params) {
  return request({
    url: `/pc/flow/seal?flowName=${params.flowName}`,
    method: 'GET'
  })
}

// 获取归档审批流程人员
export function getProcessStaffList() {
  return request({
    url: `/pc/details/staff/show`,
    method: 'GET'
  })
}

// 获取远程审批流程人员
export function getStaffRemoteList() {
  return request({
    url: `/pc/details/staff/remote/show`,
    method: 'GET'
  })
}

// 获取添加用印所有印章
export function getApplySealList() {
  return request({
    url: `/pc/details/seal/show`,
    method: 'GET'
  })
}

// 获取添加用印所有流程
export function getApplyProcessList() {
  return request({
    url: `/pc/flow/seal`,
    method: 'GET'
  })
}

// 获取添加用印中审批流程的审批人
export function getApplyProcessStaff(params) {
  return request({
    url: `/pc/details/flow/user/${params.id}`,
    method: 'GET'
  })
}

// 获取归档的所有流程
// export function getArchivedProcessList() {
//   return request({
//     url: `/pc/details/flow/archived`,
//     method: 'GET'
//   })
// }

// 获取指纹管理列表
export function getFingerPrintList(params) {
  return request({
    url: `/pc/fingerprint/show`,
    method: 'GET',
    params
  })
}

// 删除指纹管理列表
export function delFingerPrintList(params) {
  return request({
    url: `/pc/fingerprint`,
    method: 'DELETE',
    data: params
  })
}
// 校验流程名字是否重复
export function validateProcessName(params) {
  return request({
    url: `pc/details/flow/name`,
    method: 'PUT',
    params
  })
}

// 参数设置查询
export function getConfigure(params) {
  return request({
    url: `/pc/apply/configure`,
    method: 'GET',
    params
  })
}
// 更新参数设置
// export function putConfigure(params) {
//   return request({
//     url: `/pc/businessManage/configure`,
//     method: 'PUT',
//     params
//   })
// }
export function putConfigure(params) {
  return request({
    url: `/pc/businessManage/configure`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// ocr权限查找
export function getOcrJurisdiction() {
  return request({
    url: `pc/apply/ocr`,
    method: 'GET'
  })
}

// 获取当前用户部门权限
export function getDepartmentId() {
  return request({
    url: `pc/user/getDepartmentId`,
    method: 'GET'
  })
}
// 印章适用范围
export function getscope(params) {
  return request({
    url: `pc/seal/organization/?sealId=${params.sealId}`,
    // url: `pc/organization/selector/?sealId=${params.sealId}`,
    method: 'GET'
  })
}
//印章适用范围子级
export function getscopelower(params) {
  return request({
    url: `/pc/department/organization/${params.departmentId}`,
    method: 'GET'
  })
}
//印章适用范围搜索
export function getSearchData(params) {
  return request({
    // url: `/pc/department/organization/search?searchName=${params.search}`,
    url: `pc/organization/selector`,
    method: 'GET',
    params
  })
}
//印章详情
export function getViewData(params) {
  return request({
    url: `/pc/seal/${params.sealId}`,
    method: 'GET'
  })
}
// 修改印章
export function addSeal(params) {
  return request({
    url: `/pc/seal`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 修改印章
export function getGpy(params) {
  return request({
    url: `/pc/photograph/bind`,
    method: 'GET',
    data: params
  })
}

//组织架构人员排序
export function personnelRanking(enterpriseId, params) {
  return request({
    url: `pc/organization/sort/${enterpriseId}`,
    method: 'POST',
    data: params
  })
}

//获取补盖流程详情
export function getProcessDetail(params) {
  return request({
    url: `pc/flow/info/${params.flowId}`,
    method: 'GET'
  })
}

//获取唯一补盖流程
export function getProcessOnly() {
  return request({
    url: `pc/flow/coverList/only`,
    method: 'GET'
  })
}

//人员排序获取企业全部人员
export function getAll() {
  return request({
    url: `pc/organization/staff/all`,
    method: 'GET'
  })
}

// 角色人员提醒
export function verifyRoleStaff(params) {
  return request({
    url: '/pc/role/replace/verify',
    method: 'POST',
    params
  })
}

//查询当前员工可用流程名称
export function availableProcess() {
  return request({
    url: `/pc/flowable/available`,
    method: 'GET'
  })
}

//校验流程名称是否可用
export function isAvailableProcess(params) {
  return request({
    url: `/pc/flowable/isAvailable`,
    method: 'GET',
    params
  })
}

//PC用印申请重新提交-获取回显数据
export function canResubmit(params) {
  return request({
    url: `/pc/apply/reApply/check/${params}`,
    method: 'GET'
  })
}

//批量调整部门Api
export function changeDepartmentApi(params) {
  return request({
    url: `/pc/organization/staff/adjustDept`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

//部门人员选择器
export function getDepartmentOrStaff(params) {
  return request({
    url: `/pc/organization/selector`,
    method: 'GET',
    params
  })
}

//是否有开启抓拍的流程
export function checkProcessOpenStatus() {
  return request({
    url: `/pc/businessManage/configure/exist/ass`,
    method: 'GET'
  })
}

//是否绑定印控仪
export function getBind() {
  return request({
    url: `/pc/photograph/bind`,
    method: 'GET'
  })
}

//获取操作日志列表
export function getOperationLog(params) {
  return request({
    url: `/pc/operation/log`,
    method: 'GET',
    params
  })
}

//获取操作日志详情
export function getOperationLogDetail(params) {
  return request({
    url: `/pc/operation/log/details`,
    method: 'GET',
    params
  })
}

//操作日志列表导出
export const exportOperationLog = params => {
  const sessionId = localStorage.getItem('yda-qywx-sessionId')
  if (sessionId != 'null' && sessionId) {
    params.sessionId = sessionId
  }
  return process.env.VUE_APP_API_BASE_URL + `/pc/operation/log/export${objToString(params)}`
}
// 部门转化人员id
export function transformPeople(data) {
  return request({
    url: '/pc/organization/dept/covert/staff',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 移交管理员
export function transferManage(data) {
  return request({
    url: `/pc/organization/transfer/${data.transferStaffId}`,
    method: 'PUT'
  })
}

//消息管理查询
export function getMessage() {
  return request({
    url: `/pc/messageVice`,
    method: 'GET'
  })
}

//消息管理修改
export function changeMessage(data) {
  return request({
    url: `/pc/messageVice`,
    method: 'PUT',
    data
  })
}

//获取文件类型列表
export function getTypeList(params) {
  return request({
    url: `/pc/file/type`,
    method: 'GET',
    params
  })
}

//获取归档流程设计文件类型//getArchiveTypeList
export function getArchiveTypeList(params) {
  return request({
    url: `/pc/flowable/archive/allFileType`,
    method: 'GET',
    params
  })
}

//提交文件类型列表
export function addTypeList(data) {
  return request({
    url: `/pc/file/type`,
    method: 'POST',
    data
  })
}
export function setTypeList(data) {
  return request({
    url: `/pc/file/type`,
    method: 'PUT',
    data
  })
}

export function deleteTypeList(params) {
  return request({
    url: `/pc/file/type/${params}`,
    method: 'DELETE',
  })
}

// 加签
export function getSelector(params) {
  return request({
    url: `pc/organization/addMultiSelector`,
    method: 'GET',
    params
  })
}