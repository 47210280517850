<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-20 15:09:31
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-04-24 10:04:29
 * @FilePath: \yda_web_manage\src\components\icons\iconFont.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <IconFont />
</template>

<script setup>
import { createFromIconfontCN } from '@ant-design/icons-vue'
const IconFont = createFromIconfontCN({
  scriptUrl: '/iconfont.js' // 在 iconfont.cn 上生成
})
</script>
<style>
</style>
