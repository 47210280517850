/*
 * @version: 1.0
 * @Date: 2023-04-25 17:06:58
 * @LastEditors: chenxu chenxu@libawall.cn
 */
// import Vue from 'vue'
// import Vuex from 'vuex'
// import { createStore } from 'vuex'
// Vue.use(Vuex)

const process = {
  state: {
    nodeMap: new Map(),
    isEdit: null,
    selectedNode: {},
    selectFormItem: null, // 选中的控件的字段信息
    design: {},
    runningList: [],
    noTakeList: [],
    endList: [],
    diagramMode: 'design',
    basic: {
      icon: '', // 图标
      name: undefined, // 流程名称
      type: '1', //业务类型
      remark: '', // 说明
      scopeList: 'all', // 适用范围
      sealList: [], // 印章
      coverList: 'business', // 补盖审批人
      custodianList: [], // 流程负责人列表
      coverSwitch: 2, // 补盖审批人设置
      scopeType: 1,
      coverType: 1,
      sealType: 1,
      // TODO：
      beforeSealPhoto: 2, // 用印前是否拍照
      afterSealSnap: 1, // 是否抓拍
      snapInterval: '1.5', //自动抓拍时间间隔
      makeUp: 2, //是否强制补拍
      authentication: 1, //盖印需要盖印人验证指纹
      usageTime: 1, //用印时间限制
      printTime: 3, //用印人在获得审批后几天内完成用印
      stampingTime: 6, //用印人在发生盖印后几小时内完成用印
      locationLimit: 2, //用印地点限制
      fastSeal: 1, //是否开启指纹快捷用印
      takeOut: 2 // TODO:是否外带必填,"强制开启外带 1、开启 2、关闭
    }, // 基础信息
    isSetDays: false,
    isSetHours: false,
    defaultBasic: {
      icon: '', // 图标
      name: undefined, // 流程名称
      type: '1', //业务类型
      remark: '', // 说明
      scopeList: 'all', // 适用范围
      sealList: [], // 印章
      coverList: 'business', // 补盖审批人
      custodianList: [], // 流程负责人列表
      coverSwitch: 2, // 补盖审批人设置
      scopeType: 1,
      coverType: 1,
      beforeSealPhoto: 2, // 用印前是否拍照
      afterSealSnap: 1, // 是否抓拍
      snapInterval: '1.5',
      sealRange: 1
    },
    logFormItems: [], //操作日志中FormItems
    isPreview: false //是否只能预览，操作日志详情
  },
  mutations: {
    selectedNode(state, val) {
      state.selectedNode = val
    },
    loadForm(state, val) {
      state.design = val
    },
    setIsEdit(state, val) {
      state.isEdit = val
    },
    setSelectFormItem(state, val) {
      console.log('setSelectFormItem接收的val', val)
      state.selectFormItem = val
    },
    setSelectFormItems(state, val) {
      state.design.formItems = val
    },
    resetProcessData(state, pyload) {
      const { type } = pyload
      state.selectFormItem = null
      state.design = {}
      state.basic = { ...state.defaultBasic }
      state.isPreview = type == 2
    },
    setIsPreview(state, val) {
      state.isPreview = val
    },
    mergeBasicInfo(state, val) {
      // 合并基础信息（操作日志）
      state.basic = { ...state.basic, ...val }
    },
    setLogFormItems(state, val) {
      state.logFormItems = val
    },
    setDesignProcess(state, val) {
      state.design.process = val
    }
  },
  getters: {},
  actions: {},
  modules: {}
}
export default process
