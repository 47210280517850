import { createStore } from 'vuex'
import router from './router'
import user from './user'
import globalData from './globalData'
import enums from './enums'
import getters from './getters'
import diyForm from './diyForm'
import process from './process'
import sealManage from './sealManage'
// 非模块状态
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  getters,
  modules: {
    router,
    user,
    globalData,
    enums,
    diyForm,
    process,
    sealManage
  }
})
