import request from '@/utils/request'

const api = {
  applyList: '/pc/record',
  apply: '/pc/document',
  details: '/pc/details'
}

function objToString(obj) {
  const strArr = []
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      strArr.push(`${key}=${obj[key]}`)
    }
  }
  const str = strArr.length ? '?' + strArr.join('&') : ''
  return str
}

// 获取用印申请记录
export function getApply(params) {
  return request({
    // url: `/pc/apply/document/show/v2.5.9`,
    url: '/pc/apply/document',
    method: 'GET',
    params
  })
}

// 添加用印申请时-获取审批流程数据
export function getapplyData(data) {
  return request({
    url: 'pc/workspace/process/info',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 添加用印申请
export function addApply(params) {
  return request({
    url: `/pc/apply/v2.5.7`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

//重新提交用印申请
export function resubmitApply(params) {
  return request({
    url: `/pc/apply/resubmit`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

//重新提交用印申请
export function endApply(documentId) {
  return request({
    url: `/pc/apply/terminate/process/${documentId}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 获取添加用印详情
export function getApplyDetail(documentId) {
  return request({
    url: `/pc/details/document/${documentId}/v2.6.6`,
    method: 'GET'
  })
}

// 导出用印申请
export function exportApply(params) {
  const sessionId = localStorage.getItem('yda-qywx-sessionId')
  if (sessionId != 'null' && sessionId) {
    params.sessionId = sessionId
  }

  return `${process.env.VUE_APP_API_BASE_URL}/pc/apply/document/derive${objToString(params)}`
}

// 获取常规用印记录
export function getApplyList(params) {
  return request({
    // url: `${api.applyList}/document/show/v2.5.9`,
    url: '/pc/sealComplete/document/complete',
    method: 'GET',
    params
  })
}

// 获取指纹用印记录
export function getFingerPrintList(params) {
  return request({
    url: `${api.applyList}/fingerprint/show`,
    method: 'GET',
    params
  })
}

// 导出常规用印记录
export function exportBaseList(params) {
  const sessionId = localStorage.getItem('yda-qywx-sessionId')
  if (sessionId != 'null' && sessionId) {
    params.sessionId = sessionId
  }
  // return process.env.VUE_APP_API_BASE_URL + `${api.applyList}/document/derive/v2.5.7${objToString(params)}`
  return `${process.env.VUE_APP_API_BASE_URL}/pc/record/document/derive${objToString(params)}`
}

// 导出指纹用印记录
export function exportFingerList(params) {
  const sessionId = localStorage.getItem('yda-qywx-sessionId')
  if (sessionId != 'null' && sessionId) {
    params.sessionId = sessionId
  }
  return process.env.VUE_APP_API_BASE_URL + `${api.applyList}/fingerprint/derive${objToString(params)}`
}

// 获取常规用印详情
export function getApplyListDetail(documentId, sealId) {
  return request({
    url: `${api.details}/document/info/${documentId}/${sealId}/v2.6.6`,
    method: 'GET'
    // params
  })
}

// 获取特权用印详情
export function getFingerprintDetail(params) {
  return request({
    url: `${api.details}/fingerprint/${params.documentId}`,
    method: 'GET'
    // params
  })
}

// 提交归档
export function sendArchived(params) {
  return request({
    url: `${api.apply}/archived`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 撤销用印申请
export function backoutSeal(params) {
  return request({
    url: `/pc/apply/backout/${params.id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 撤销用印申请理由(v2.6.3新增)
export function revocationReason(params) {
  return request({
    // url: '/pc/apply/withdraw/v2.6.3',
    url: `/pc/apply/withdraw`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    params
  })
}

//重新提交回显用印申请
export function getEchoDetail(params) {
  return request({
    url: `/pc/apply/document/info/${params.id}/v2.5.9`,
    method: 'GET'
  })
}

//企业是否认证，授权，开通电子签章功能
export function isCertification() {
  return request({
    url: `/pc/qys/enterprise/auth/status`,
    method: 'GET'
  })
}

//用印申请详情
export function sealDetail(params) {
  return request({
    url: `/pc/apply/document/details`,
    method: 'GET',
    params
  })
}
// 根据模板ID获取动态表单和动态流程展示
export function getProcessDetail(params) {
  return request({
    url: 'pc/workspace/process/detail',
    method: 'GET',
    params
  })
}

// 3.0新增用印申请
export function applyAdd(data) {
  return request({
    url: '/pc/apply/submit',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 3.0PC 用印申请-重新提交-提交
export function reApplySubmit(data) {
  return request({
    url: '/pc/apply/reApply',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
//用印记录详情
export function recordDetail(params) {
  return request({
    url: '/pc/record/document/details',
    method: 'GET',
    params
  })
}
//用印记录详情 ocr文件对比记录
export function fileCheckDetail(params) {
  return request({
    url: '/pc/ocr/file/details',
    method: 'GET',
    params
  })
}
// 获取文件信息 3.0
export function getFiles(params) {
  return request({
    url: '/file',
    method: 'GET',
    params
  })
}
// 催办
export function toUrge(params){
  return request({
    url:'pc/details/urge',
    method:'POST',
    data: params
  })
}

//用印申请-所在部门选择
export function getAllDept() {
  return request({
    url: '/pc/flowable/dept/list',
    method: 'GET'
  })
}

//用印申请-校验是否有可选择的流程名称
export function checkProcess() {
  return request({
    url: '/pc/flowable/process/check',
    method: 'GET'
  })
}

//用印申请-根据部门查询流程名称列表
export function getProcessList(params) {
  return request({
    url: '/pc/flowable/process/list',
    method: 'GET',
    params
  })
}

// 获取待用印列表
export function getSealWaitList(params) {
  return request({
    url: '/pc/sealWait/document/stay',
    method: 'GET',
    params
  })
}

//获取已完成列表
export function getSealCompleteList(params) {
  return request({
    url: '/pc/sealComplete/document/complete',
    method: 'GET',
    params
  })
}
export function getSealArchiveList(params) {
  return request({
    url: '/pc/sealArchive/document/sealArchive',
    method: 'GET',
    params
  })
}
export function getNeedMakeUp(params) {
  return request({
    url: `/pc/sealComplete/isNeedMakeUp/${params}`,
    method: 'GET',
  })
}