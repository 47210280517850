import { SET_USER_INFO, SET_PERMISSION_ERROR_NUM, REST_PERMISSION_ERROR_NUM } from './mutations-type'
const mutations = {
  [SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo
  },
  [SET_PERMISSION_ERROR_NUM](state) {
    state.permissionErrorNum += 1
  },
  [REST_PERMISSION_ERROR_NUM](state) {
    state.permissionErrorNum = 0
  }
}

export default mutations
