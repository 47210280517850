/*
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-19 10:41:00
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-03 11:26:57
 * @FilePath: \yda_web_manage\src\store\diyForm\mutations.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const mutations = {
  // 处理rules的类型
  getValueType(type) {
    return type.replace(type.substr(0, 1), type.substr(0, 1).toLocaleLowerCase())
  },
  getMessage(controlName, titles) {
    if (controlName === 'FileUpload') return `请上传${titles}`
    const list = ['TextInput', 'TextareaInput', 'AmountInput', 'NumberInput', 'Phone']
    return list.includes(controlName) ? `请输入${titles}` : `请选择${titles}`
  },
  // 处理rule数组
  disposeRules(data) {
    let ruleList = [
      {
        required: true,
        message: mutations.getMessage(data.controlName, data.titles),
        trigger: 'blur',
        type: mutations.getValueType(data.valueType)
      }
    ]
    if (data.controlName === 'MultipleSelect') {
      ruleList.push({
        required: true,
        message: mutations.getMessage(data.controlName, data.titles),
        trigger: 'change',
        type: mutations.getValueType(data.valueType)
      })
    }
    return ruleList
  },

  // 处理整体表单rules
  loadFormConfig(state, forms) {
    console.log('看看表单内容', forms)
    forms.forEach(item => {
      if (item.controlName !== 'TakeOut' && item.controlName !== 'SealInfo') {
        if (item.props.required) {
          let rulesList = mutations.disposeRules(item)
          mutations.SET_RULES(state, {
            id: item.id,
            rulesList
          })
        }
      }
    })
  },

  // 同步整体表单数据
  SET_CONTROL_LIST(state, list) {
    state.controlList = list
    // mutations.SET_FORM_DOME_LIST(state, list)
    // 处理form表单
    let obj = {}
    list.forEach(item => {
      if (item.controlName === 'TakeOut') {
        item.props.items.forEach(el => {
          obj[el.id] = undefined
        })
      }
      obj[item.id] = undefined
    })
    mutations.SET_FORM(state, obj)
  },
  // 盖印方式设置默认值
  SET_REMOTE_DEFAULT_VALUE(state, obj) {
    state.form[obj.id] = obj.value
    state.remoteAuditorVO = obj.remoteAuditorVO
    state.hasSelf = obj.hasSelf
    console.log('obj', obj)
    if (obj.value === '远程盖印') {
      mutations.INSERT_FORM_ITEM(state, { fixType: 'REMOTE', operation: 'add' })
    }
  }, 
  // 插入远程确认人
  INSERT_FORM_ITEM(state, data) {
    if (data.fixType === 'REMOTE') {
      if (data.operation === 'add') {
        // const index = state.controlList.findIndex(item => item.type === 'REMOTE')
        const index = state.controlList.findIndex(item => item.fixType === 'REMOTE')
        if (index > -1) {
          state.controlList.splice(index + 1, 0, {
            id: 'confirmerId1',
            titles: '远程确认人',
            controlName: 'RemoteStaff',
            value: '',
            valueType: 'String',
            props: {
              required: true,
              enablePrint: true,
              placeholder: '远程确认人'
            }
          })
          if (!state.form['confirmerId1']) {
            state.form['confirmerId1'] = undefined
          }
          if (data.remoteAuditorVO != null) {
            state.remoteAuditorVO = data?.remoteAuditorVO
          }
          state.showConfirmer = true
          mutations.loadFormConfig(state, state.controlList)
        }
      } else {
        const index = state.controlList.findIndex(item => item.id === 'confirmerId1')
        if (index > -1) {
          state.showConfirmer = false
          state.controlList.splice(index, 1)
          delete state.form.confirmerId1
          delete state.rules.confirmerId1
        }
      }
    }
  },
  // 设置远程盖印人
  SET_REMOTR_PROPLE(state, obj) {
    state.remoteAuditorVO = obj
  },
  // 所有保管员列表
  SET_KEEPER_LIST(state, obj) {
    state.sealFormData.keeperObj = obj
  },
  // 修改整体form
  SET_FORM(state, obj) {
    state.form = { ...obj, ...state.form }
    mutations.loadFormConfig(state, state.controlList)
  },
  // 重新提交填充form数据
  SET_RESUBMIT_DATA(state, obj) {
    state.form = { ...state.form, ...obj }
    mutations.loadFormConfig(state, state.controlList)
  },
  // 修改开关状态
  SET_SWITCH_STATUS(state, status) {
    console.log('看看status的值', status)
    state.switchStatus = status
    const index = state.controlList.findIndex(item => item.controlName === 'TakeOut')
    console.log('看看index的值', index)
    // TODO:如果外带开启必填，默认展开外带填写信息
    if (status) {
      console.log('state.controlList[index] 是啥', state.controlList[index])
      state.controlList[index] ? mutations.loadFormConfig(state, state.controlList[index].props.items) : ''
    } else {
      state.form['takeOutId1'] = undefined
      state.form['takeOutId2'] = undefined
      if (state.controlList[index]) {
        state.controlList[index].props.items.forEach(el => {
          // 删除检验规则中的数据
          delete state.rules[el.id]
        })
      }
    }
  },
  // 修改rules
  SET_RULES(state, data) {
    if (!state.rules) {
      state.rules = {}
    }
    state.rules[data.id] = data.rulesList
  },
  // 设置流程名称id
  SET_PROCESS_ID(state, data) {
    state.processId = data.processId
    state.processDefinitionId = data.processDefinitionId
  },
  // 同步印章列表
  SET_SEAL_LIST(state, list) {
    state.sealFormData.sealList = list
  },
  // 更新印章列表中印章禁用状态
  SET_SEAL_LIST_DISABLED(state, params) {
    console.log(params, '6666/')
    const keyList = Object.keys(state.sealFormData.forms)
    const applySealList = keyList.filter(item => item.includes('applySeal'))

    const selectSealList = applySealList.map(item => state.sealFormData.forms[item])
    state.sealFormData.sealList.forEach(item => {
      item[0].props.options.forEach(el => {
        el.disabled = selectSealList.includes(el.value)
      })
    })
  },
  // 插入新的印章
  INSERT_SEAL_ITEM(state, data) {
    const { sealDataList, item, index, date } = data
    state.sealFormData.sealList.push(sealDataList)
    sealDataList.forEach(item => {
      state.sealFormData.forms[item.id] = undefined
      if (item.props.required) {
        let rulesList = mutations.disposeRules(item)
        mutations.SET_SEAL_FORM_RULE_ITEM(state, {
          id: item.id,
          rulesList
        })
      }
    })
    mutations.SET_DEFAULT_SELECT(state, { item, index, date })
  },
  // 删除某个印章
  SET_DEL_SEAL_ITEM(state, index) {
    const delId = state.sealFormData.sealList[index].map(item => item.id)
    delId.forEach(item => {
      delete state.sealFormData.forms[item]
      delete state.sealFormData.rules[item]
    })
    state.sealFormData.sealList.splice(index, 1)
    mutations.SET_SEAL_LIST_DISABLED(state, '')
  },
  // 同步印章列表form
  SET_SEAL_LIST_FORM(state, data) {
    let form = state.sealFormData.forms
    state.sealFormData.rules = null
    data.forEach(item => {
      item.forEach(el => {
        if (!form[el.id]) {
          form[el.id] = undefined
        }
        // 处理rules
        if (el.props.required) {
          let rulesList = mutations.disposeRules(el)
          mutations.SET_SEAL_FORM_RULE_ITEM(state, {
            id: el.id,
            rulesList
          })
        }
      })
    })
    state.sealFormData.forms = form
  },
  // 设置印章form中rules
  SET_SEAL_FORM_RULE_ITEM(state, data) {
    if (!state.sealFormData.rules) {
      state.sealFormData.rules = {}
    }
    state.sealFormData.rules[data.id] = data.rulesList
  },
  // 重新提交时同步印章列表form中数据
  SET_RESUBMIT_SEAL_DATA(state, data) {
    state.sealFormData.forms[data.key] = data.value
  },
  // 重新提交-设置用印申请记录ID
  SET_RESUBMIT_RECORD(state, data) {
    state.recordId = data.recordId
  },
  // 设置停用印章列表
  SET_SEAL_DEACTIVATE_ID(state, list) {
    state.sealDeactivateId = list
  },
  // 清空所有数据
  EMPTY_ALL_DATA(state) {
    let obj = {
      processId: null, // 流程名称ID
      processDefinitionId: null, // 流程名称定义ID
      // 整个用印申请使用
      controlList: [],
      form: {},
      switchStatus: false,
      showConfirmer: false,
      rules: null,
      // 用印申请-印章列表使用
      sealFormData: {
        formRef: undefined,
        sealList: [
          [
            {
              id: 'applySeal0',
              titles: '申请印章',
              controlName: 'SelectInput',
              value: '',
              valueType: 'Number',
              props: {
                required: true,
                enablePrint: true,
                expanding: false,
                options: [{ disabled: false, value: '', label: '' }]
              }
            },
            {
              id: 'applyNum0',
              titles: '申请次数',
              controlName: 'NumberInput',
              value: '',
              valueType: 'Number',
              props: {
                required: true,
                enablePrint: true
              }
            },
            {
              id: 'sealer0',
              titles: '盖印人',
              controlName: 'SelectInput',
              value: '',
              valueType: 'String',
              props: {
                required: true,
                options: ['申请人', '保管员']
              }
            }
          ]
        ],
        forms: {},
        rules: null,
        keeperObj: {} // 所有保管员列表
      },
      recordId: null,
      sealDeactivateId: null,
      isFile: false
    }
    Object.keys(obj).forEach(item => {
      state[item] = obj[item]
    })
  },
  // 设置印章盖印人默认选中
  SET_DEFAULT_SELECT(state, ploayd) {
    const { item, index, date } = ploayd
    const seal = state.sealFormData.sealList[index]
    const val = item.props.items[item.props.items.length - 1]
    console.log(seal)
    if (Array.isArray(seal)) {
      //兼容之前的没有配置默认项流程
      const stamp = val.props.stamp || [
        { title: '申请人', isCheck: false },
        //老流程 （区分申请[默认选中保管员]与重新提交[回显]）
        { title: '保管员', isCheck: !ploayd.isReSubmit }
      ]
      //配置默认选择的盖印人
      const title = stamp.find(item => item.isCheck)?.title
      //控制第一次默认选中保管员但没有选择印章时不显示保管员信息
      seal[seal?.length - 1].isInitShow = title == '保管员' && !ploayd.isReSubmit ? false : true
      if (!ploayd.isReSubmit) {
        if (index == 0) {
          state.sealFormData.forms['sealer' + index] = title
        } else {
          state.sealFormData.forms['sealer' + date] = title
        }
      }
      //配置盖印人选项
      seal[seal?.length - 1].props.options = stamp.map(item => item.title)
    }
  },
  //更新控制保管员显示的值
  UPDATE_SELECT_SHOW(state, ploayd) {
    const seal = state.sealFormData.sealList.find(item => {
      //选择印章与选择盖印人
      const sealer = ploayd.typeId == 1 ? item[item?.length - 1] : item[0]
      return sealer.id == ploayd.id
    })
    console.log('印章', seal, ploayd)
    if (Array.isArray(seal)) {
      seal[seal?.length - 1].isInitShow = true
    }
  },
  //设置某个印章的选中状态
  UPDATE_SEAL_STATUS(state, ploayd) {
    const { sealId, disabled } = ploayd
    state.sealFormData.sealList.forEach(item => {
      item[0].props.options.forEach(el => {
        if (el.value == sealId) {
          el.disabled = disabled
        }
      })
    })
  },
  //设置所在部门id
  SET_DEPARTMENT_ID(state, data) {
    state.deptId = data
  },
  //设置二维码水印参数
  SET_QRCODE_DATA(state, data) {
    state.qrcodeForm = data
  }
}
export default mutations
