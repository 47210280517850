<template>
  <a-spin :spinning="pageReload">
    <a-layout class="basic-layout">
      <a-layout-sider class="layout-sider">
        <nav-menu></nav-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header class="layout-header">
          <my-header></my-header>
        </a-layout-header>
        <a-layout-content class="layout-content">
          <section class="view-container">
            <div class="bread-crumb" v-if="$route.path != '/dashboard/analysis'">
              <bread-crumb />
            </div>
            <router-view></router-view>
          </section>
        </a-layout-content>
        <a-layout-footer class="layout-footer"><myFooter /></a-layout-footer>
      </a-layout>
    </a-layout>
  </a-spin>
</template>

<script>
import navMenu from '@/components/NavMenu/index'
import myHeader from '@/components/Header/index'
import BreadCrumb from '@/components/BreadCrumb'
import myFooter from '@/components/Footer'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
export default {
  components: {
    navMenu,
    myHeader,
    myFooter,
    BreadCrumb
  },
  setup() {
    const store = useStore()
    const pageReload = computed(() => store.state.globalData.pageReload)

    return {
      pageReload
    }
  }
}
</script>

<style lang="scss">
.basic-layout {
  width: 100vw;
  background-color: #fff;
  color: #fff;
  min-width: 1280px;
  .layout-header {
    height: 48px;
    padding: 0;
    line-height: 0;
    z-index: 20;
  }
  .layout-sider {
    flex: 0 0 208px !important;
    max-width: 208px !important;
    min-width: 208px !important;
    width: 208px !important;
    background: #001529;
    box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
    position: fixed;
    height: 100%;
    // padding-top: 48px;
    margin-top: 48px;
    overflow-y: auto;
    z-index: 10;
    scrollbar-width: none;
  }
  .layout-content {
    // overflow-x: auto;
    background: #f2f3f7;
    // width: calc(100vw - 208px);
    margin-left: 208px;
    // min-width: 1280px;
    // min-height: calc(100vh - 110px);
    min-height: calc(100vh - 48px);
    box-sizing: content-box;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: block;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(202, 202, 202);
      // border-radius: 4px;
    }
    .view-container {
      width: 100%;
      min-width: 1072px;
      position: reactive;
      .bread-crumb {
        margin-bottom: 24px;
      }
    }
  }
  .layout-footer {
    width: calc(100vw - 208px);
    margin-left: 208px;
  }
}
</style>
