const sealManage = {
  namespaced: true,
  state: {
    checkedRows: [],
    paginationParams: {
      pageSize: 10,
      total: 0,
      current: 1,
      index: 0,
      'show-size-changer': true,
      'show-quick-jumper': true
    },
    //用印申请筛选参数
    sealParams: null,
    //用印记录筛选参数
    sealRecordParams: null,
    //特权搜索条件
    sealFingerParams: null
  },
  mutations: {
    SET_SEALPARAMS(state, val) {
      state.sealParams = val
    },
    SET_SEALRECORDPARAMS(state, val) {
      state.sealRecordParams = val
    },
    SET_SEALFINGERPARAMS(state, val) {
      state.sealFingerParams = val
    },
    CLEAECACHE(state) {
      state.sealFingerParams = null
      state.sealRecordParams = null
      state.sealParams = null
    }
  }
}

export default sealManage
