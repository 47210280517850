/*
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-18 17:31:12
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-01 08:11:41
 * @FilePath: \yda_web_manage\src\store\diyForm\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import mutations from './mutations'
const diyForm = {
  namesspaced: true,
  state: {
    deptId: null, //部门Id
    processId: null, // 流程名称ID
    processDefinitionId: null, // 流程名称定义ID
    // 整个用印申请使用
    controlList: [],
    form: {},
    switchStatus: false,
    showConfirmer: false,
    rules: null,
    // 用印申请-印章列表使用
    sealFormData: {
      formRef: undefined,
      sealList: [
        [
          {
            id: 'applySeal0',
            titles: '申请印章',
            controlName: 'SelectInput',
            value: '',
            valueType: 'Number',
            props: {
              required: true,
              enablePrint: true,
              expanding: false,
              options: [{ disabled: false, value: '', label: '' }]
            }
          },
          {
            id: 'applyNum0',
            titles: '申请次数',
            controlName: 'NumberInput',
            value: '',
            valueType: 'Number',
            props: {
              required: true,
              enablePrint: true
            }
          },
          {
            id: 'sealer0',
            titles: '盖印人',
            controlName: 'SelectInput',
            value: '',
            valueType: 'String',
            props: {
              required: true,
              options: ['申请人', '保管员']
            }
          }
        ]
      ],
      forms: {},
      rules: null,
      keeperObj: {} // 所有保管员列表
    },
    // 重新提交使用
    recordId: null,
    // 提示时-停用的印章列表
    sealDeactivateId: null,
    // 远程确认人
    remoteAuditorVO: [],
    hasSelf: false,
    qrcodeForm: {
      //二维码水印配置参数
    }
  },
  mutations
}

export default diyForm
