import {
  SET_APPLY_PROCESS_LIST,
  SET_IS_Fetch_APPLY_PROCESS,
  SET_APPLY_UPDATE,
  SET_ROOT_SIZE,
  SET_PAGE_RELOAD,
  SET_BACK_ROUTER
} from './mutations-type'
export default {
  [SET_APPLY_PROCESS_LIST](state, list) {
    state.applyProcessList = list
  },
  [SET_IS_Fetch_APPLY_PROCESS](state, result) {
    state.isFetchApplyProcess = result
  },
  [SET_APPLY_UPDATE](state, result) {
    if (Array.isArray(result)) {
      state.applyUpdate = result
    } else {
      state.applyUpdate = state.applyUpdate.filter(item => item !== result)
    }
  },
  [SET_ROOT_SIZE](state, fontSize) {
    fontSize !== state.rootSize && (state.rootSize = fontSize)
  },
  [SET_PAGE_RELOAD](state, isReload) {
    state.pageReload = isReload
  },
  [SET_BACK_ROUTER](state, value) {
    state.backRouter = value
  },
  'SET_LAST_DEPARTMENT_INFO'(state, value) {
    state.lastDepartmentInfo = value
  },
  'ADD_LAST_DEPARTMENT_INFO'(state) {
    if (state.lastDepartmentInfo) {
      state.lastDepartmentInfo.n++
    }
    if (state.lastDepartmentInfo?.n >= 2) {
      state.lastDepartmentInfo = { n: 0 }
    }
  }
}
